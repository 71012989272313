import React from "react"
import "../styles/Credits.css"

export default function Credits(){

    return (
        <div id="credits">
            <div>
                Built and designed by Sair Abbas.
            </div>
            <div>
                All rights reserved. ©
            </div>
        </div>
    )
}